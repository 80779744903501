import React from 'react'
import CodeSnippet from '../../CodeSnippet'
import ResponseCode from '../../ResponseCode'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'

interface IRestTryOutResponse {
  sendingRequest: boolean
  responseFromApi: AxiosResponse<any, any> | Record<any, any>
}

const RestTryOutResponse: React.FC<IRestTryOutResponse> = ({
  sendingRequest,
  responseFromApi,
}) => {
  const { t } = useTranslation()
  const getResponsePlaceholder = (): string => {
    if (sendingRequest) {
      return t('tryOut.noResponseYet')
    } else {
      return `${t('tryOut.noResponseYet')} ${t('tryOut.clickRunButton')}`
    }
  }

  return responseFromApi && responseFromApi.status ? (
    <CodeSnippet
      canCopy
      codeString={JSON.stringify(responseFromApi.data, null, 2)}
      className="has-response"
      language="json"
      headerContentsLeftSide={
        <>
          <ResponseCode code={responseFromApi.status} />
          <span>{t('generalDocumentation.response')}</span>
        </>
      }
    />
  ) : (
    <CodeSnippet
      codeString={`// ${getResponsePlaceholder()}`}
      className="no-response"
      language="javascript"
      canCopy={false}
      headerContentsLeftSide={<span>{t('generalDocumentation.response')}</span>}
    />
  )
}

export default RestTryOutResponse
