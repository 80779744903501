import { AxiosResponse } from 'axios'
import React, { KeyboardEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRestParameters } from '../../../interfaces/restInterfaces'
import httpService from '../../../services/httpService'
import {
  bucketParameters,
  prepareEndpoint,
  sortParameters,
  onKeyDown,
  getRequestHeaders,
  findAndReplaceValueInArr,
  sendApiTryOutMetrics,
  handleResponseData,
  prepareQueryParameters,
} from '../../../utils/tryout.utils'
import BearerTokenInput from '../../BearerToken'
import CodeSnippetHeader from '../../CodeSnippet/CodeSnippetHeader'
import { getSupportedParams } from '../../../utils/documentation.utils'
import TryOutParameters from '../TryOutParameters'
import { getBffBaseUrl } from '../../../services/configService'
import { objIsEmpty } from '../../../utils/general.utils'
import RestTryOutResponse from '../RestTryOutResponse'
import TryOutRequestBtn from '../TryOutRequestBtn'
import {
  jsonSyntaxErrorResponse,
  MULTIPART_FORM_DATA,
} from '../../../constants/tryOutConstants'
import FormDataRequestBody from '../FormDataRequestBody'
import { ISchemaObject } from '../../../interfaces/schemaObjectInterfaces'
import { IRestTryOutProps } from '../../../interfaces/tryOutInterfaces'

const FormDataTryOut: React.FC<IRestTryOutProps> = ({ api, name }) => {
  const { t } = useTranslation()

  const [tokenFromInput, setTokenFromInput] = useState('')
  const [sendingRequest, setSendingRequest] = useState(false)
  const [responseFromApi, setResponseFromApi] = useState(
    {} as AxiosResponse | Record<string, any>
  )

  api.parameters = getSupportedParams(sortParameters(api.parameters), 'header')

  const [apiDetails, setApiDetails] = useState<any>(api)

  const BINARY_TYPE = 'binary'
  const OBJ_TYPE = 'object'

  const mappedRequestProperties = apiDetails.requestBody.properties.map(
    (property: ISchemaObject): ISchemaObject => {
      if (apiDetails.requestBody.required?.includes(property.name)) {
        return { ...property, required: true }
      } else {
        return property
      }
    }
  )

  // using state instead of individual const to trigger react update hook
  // on child component
  const [fileParameters, setFileParameters] = useState<any>(
    mappedRequestProperties.filter(
      (property: ISchemaObject) =>
        property.format === BINARY_TYPE ||
        property.schema?.format === BINARY_TYPE
    )
  )

  const jsonParameters = mappedRequestProperties.filter(
    (property: ISchemaObject) => property.type === OBJ_TYPE
  )

  const simpleParams = mappedRequestProperties.filter(
    (property: ISchemaObject) =>
      property.type !== OBJ_TYPE &&
      property.format !== BINARY_TYPE &&
      property.schema?.format !== BINARY_TYPE
  )

  const handleAuthTokenInput = (token: string): void => {
    if (token !== tokenFromInput) {
      setTokenFromInput(token)
    }
  }

  const runHttpRequest = async (): Promise<void> => {
    const { requestBody, parameters, httpVerb, endpoint } = apiDetails
    const bucketedParameters = bucketParameters(parameters)
    const preparedQueryParameters = prepareQueryParameters(bucketedParameters)
    const preparedEndpoint = prepareEndpoint(endpoint, bucketedParameters)
    const preparedUrl = `${getBffBaseUrl()}/proxy${preparedEndpoint}`
    const requestContentType =
      requestBody?.requestContentType || MULTIPART_FORM_DATA

    const createRequestBody = (): { [key: string]: any } => {
      const body: { [key: string]: any } = {}
      requestBody.properties.forEach((property: ISchemaObject) => {
        const propertyHasContentType = property.selectedContentType
        let value: string | Blob = property.value as string
        if (propertyHasContentType && property.value) {
          value = new Blob([value], {
            type: property.selectedContentType,
          })
        }
        body[property.name as string] = value
      })
      return body
    }

    const body = createRequestBody()
    let httpResponse: AxiosResponse | { [key: string]: any } = {}

    try {
      setSendingRequest(true)

      switch (httpVerb.toLowerCase()) {
        case 'patch':
        case 'post':
        case 'put':
          type pVerb = 'patch' | 'post' | 'put'
          const operationName = httpVerb.toLowerCase() as pVerb

          if (body && !objIsEmpty(body)) {
            try {
              httpResponse = await httpService[operationName](
                preparedUrl,
                body,
                {
                  params: preparedQueryParameters,
                  headers: getRequestHeaders(
                    tokenFromInput,
                    true,
                    requestContentType
                  ),
                }
              )
            } catch (err) {
              if (err instanceof SyntaxError) {
                httpResponse = jsonSyntaxErrorResponse
              } else {
                throw err
              }
            }
          } else {
            httpResponse = await httpService[operationName](
              preparedUrl,
              {},
              {
                headers: getRequestHeaders(
                  tokenFromInput,
                  true,
                  requestContentType
                ),
              }
            )
          }
      }

      handleResponseData(
        httpResponse,
        setResponseFromApi,
        t('tryOut.tooMuchData')
      )

      sendApiTryOutMetrics(name, true)
    } catch (err) {
      setResponseFromApi(err.response)
      sendApiTryOutMetrics(name, false, err)
    } finally {
      setSendingRequest(false)

      // remove values of inputted parameters
      setApiDetails({
        ...apiDetails,
        requestBody: {
          ...apiDetails.requestBody,
          properties: apiDetails.requestBody.properties.map((property: any) => {
            delete property?.value
            return property
          }),
        },
      })
      // force update to file input field
      setFileParameters(null)
      setFileParameters(
        mappedRequestProperties.filter(
          (property: ISchemaObject) =>
            property.format === BINARY_TYPE ||
            property.schema?.format === BINARY_TYPE
        )
      )
    }
  }

  const handleParameterChange = (params: IRestParameters[]): void => {
    setApiDetails({
      ...apiDetails,
      parameters: params,
    })
  }

  const handlePropertyChange = (
    value: any,
    param: IRestParameters | ISchemaObject,
    propertyNameToChange?: string
  ): void => {
    const paramsCopy = findAndReplaceValueInArr(
      value,
      param,
      apiDetails.requestBody.properties,
      propertyNameToChange
    )

    setApiDetails({
      ...apiDetails,
      requestBody: {
        ...apiDetails.requestBody,
        properties: paramsCopy,
      },
    })
  }

  return (
    <>
      <form className="try-out">
        <CodeSnippetHeader
          canCopy={false}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.request')}</span>
          }
        />
        <div
          className={`body ${
            (responseFromApi && responseFromApi.status && 'request-sent') || ''
          } ${apiDetails.requestBody ? 'has-request-body' : ''}`}
        >
          <p className="inputs-header">{t('tryOut.header')}</p>
          <BearerTokenInput
            handleAuthTokenInput={handleAuthTokenInput}
            toggleId="REST"
          />
          {apiDetails.parameters?.length ? (
            <>
              <hr className="try-out-divider" />
              <TryOutParameters
                changeCallback={handleParameterChange}
                keyDownCallback={(arg: KeyboardEvent<HTMLInputElement>): void =>
                  onKeyDown(arg, runHttpRequest)
                }
                parameters={apiDetails.parameters}
              />
            </>
          ) : null}
          {apiDetails.requestBody ? (
            <>
              <hr className="try-out-divider" />
              <FormDataRequestBody
                handleChange={handlePropertyChange}
                fileParams={fileParameters}
                jsonParams={jsonParameters}
                simpleParams={simpleParams}
                example={apiDetails.requestExample}
                onKeyDown={(arg: KeyboardEvent<HTMLInputElement>): void =>
                  onKeyDown(arg, runHttpRequest)
                }
              />
            </>
          ) : null}
        </div>
        <div className="button-container">
          <TryOutRequestBtn
            isSendingRequest={sendingRequest}
            onClickHandler={runHttpRequest}
          />
        </div>
      </form>
      <RestTryOutResponse
        sendingRequest={sendingRequest}
        responseFromApi={responseFromApi}
      />
    </>
  )
}

export default FormDataTryOut
