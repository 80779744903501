import { AxiosResponse } from 'axios'
import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IRestDocumentation,
  IRestParameters,
} from '../../../interfaces/restInterfaces'
import httpService from '../../../services/httpService'
import {
  assembleCurlStatement,
  bucketParameters,
  prepareEndpoint,
  prepareQueryParameters,
  sortParameters,
  onKeyDown,
  getRequestHeaders,
  sendApiTryOutMetrics,
  handleResponseData,
} from '../../../utils/tryout.utils'
import BearerTokenInput from '../../BearerToken'
import CodeSnippetHeader from '../../CodeSnippet/CodeSnippetHeader'
import { getSupportedParams } from '../../../utils/documentation.utils'
import TryOutParameters from '../TryOutParameters'
import { getBffBaseUrl } from '../../../services/configService'
import RestTryOutResponse from '../RestTryOutResponse'
import TryOutRequestBtn from '../TryOutRequestBtn'
import {
  jsonSyntaxErrorResponse,
  APPLICATION_JSON,
} from '../../../constants/tryOutConstants'
import TryOutTextArea from '../TryOutTextArea'
import { IRestTryOutProps } from '../../../interfaces/tryOutInterfaces'
import { logError } from '../../../services/loggerService'
import { AppState } from '../../../state/store'
import { useSelector } from 'react-redux'
import { retrieveFeatureFlags } from '../../../state/auth'

const JsonTryOut: React.FC<IRestTryOutProps> = ({
  api,
  name,
  resourceName,
}) => {
  const { t } = useTranslation()

  const [tokenFromInput, setTokenFromInput] = useState('')
  const [canCopyCurl, setCanCopyCurl] = useState(true)
  const [sendingRequest, setSendingRequest] = useState(false)
  const [responseFromApi, setResponseFromApi] = useState(
    {} as AxiosResponse | Record<string, any>
  )

  api.parameters = getSupportedParams(sortParameters(api.parameters), 'header')
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )
  const [apiDetails, setApiDetails] = useState(api)
  const [curlStatement, setCurlStatement] = useState(() =>
    assembleCurlStatement(api, resourceName, tokenFromInput, userFeatureFlags)
  )

  const updateRequestBodyAndCurl = useCallback(
    (requestBody: string, apiDetails: IRestDocumentation, token: string) => {
      if (requestBody) {
        JSON.parse(requestBody)
      }
      // only set the curl statement when JSON is valid
      setCurlStatement(
        assembleCurlStatement(apiDetails, resourceName, token, userFeatureFlags)
      )
      setCanCopyCurl(true)
    },
    [resourceName, userFeatureFlags]
  )

  useEffect(() => {
    try {
      updateRequestBodyAndCurl(
        apiDetails.requestBody as any,
        apiDetails,
        tokenFromInput
      )
    } catch (e) {
      logError(
        `An error occured while updating the Request Body/Curl statement: ${e}`
      )
    }
  }, [updateRequestBodyAndCurl, apiDetails, tokenFromInput])

  const validateRequestBodyJson = (
    requestBody: string,
    apiDetails: IRestDocumentation,
    token: string
  ): void => {
    try {
      updateRequestBodyAndCurl(requestBody, apiDetails, token)
    } catch (e) {
      // Must be valid JSON in order to copy
      setCanCopyCurl(false)
    }
  }

  const handleAuthTokenInput = (token: string): void => {
    if (token !== tokenFromInput) {
      setTokenFromInput(token)
      if (apiDetails.requestBody) {
        validateRequestBodyJson(
          apiDetails.requestBody as any,
          apiDetails,
          token
        )
      } else {
        setCurlStatement(
          assembleCurlStatement(
            apiDetails,
            resourceName,
            token,
            userFeatureFlags
          )
        )
      }
    }
  }

  const runHttpRequest = async (): Promise<void> => {
    const { requestBody, parameters, httpVerb, endpoint } = apiDetails
    const bucketedParameters = bucketParameters(parameters)
    const preparedQueryParameters = prepareQueryParameters(bucketedParameters)
    const preparedEndpoint = prepareEndpoint(endpoint, bucketedParameters)
    const preparedUrl = `${getBffBaseUrl()}/proxy${preparedEndpoint}`
    const requestContentType = requestBody?.contentType || APPLICATION_JSON
    let httpResponse: { [key: string]: any } = {}

    try {
      setSendingRequest(true)

      switch (httpVerb.toLowerCase()) {
        case 'get':
          httpResponse = await httpService.get(preparedUrl, {
            params: preparedQueryParameters,
            headers: getRequestHeaders(
              tokenFromInput,
              false,
              requestContentType
            ),
          })
          break
        case 'delete':
          httpResponse = await httpService.delete(preparedUrl, {
            params: preparedQueryParameters,
            headers: getRequestHeaders(
              tokenFromInput,
              false,
              requestContentType
            ),
          })
          break
        case 'patch':
        case 'post':
        case 'put':
          type pVerb = 'patch' | 'post' | 'put'
          const theVerb = httpVerb.toLowerCase() as pVerb

          if (requestBody && requestBody.value) {
            try {
              const jsonConvertedData = JSON.parse(requestBody.value)
              httpResponse = await httpService[theVerb](
                preparedUrl,
                jsonConvertedData,
                {
                  params: preparedQueryParameters,
                  headers: getRequestHeaders(
                    tokenFromInput,
                    true,
                    requestContentType
                  ),
                }
              )
            } catch (err) {
              if (err instanceof SyntaxError) {
                httpResponse = jsonSyntaxErrorResponse
              } else {
                throw err
              }
            }
          } else {
            httpResponse = await httpService[theVerb](
              preparedUrl,
              {},
              {
                params: preparedQueryParameters,
                headers: getRequestHeaders(
                  tokenFromInput,
                  false,
                  requestContentType
                ),
              }
            )
          }
      }

      handleResponseData(
        httpResponse,
        setResponseFromApi,
        t('tryOut.tooMuchData')
      )

      sendApiTryOutMetrics(name, true)
    } catch (err) {
      setResponseFromApi(err.response)
      sendApiTryOutMetrics(name, false, err)
    } finally {
      setSendingRequest(false)
    }
  }

  const handleParameterChange = (params: IRestParameters[]): void => {
    setApiDetails({
      ...apiDetails,
      parameters: params,
    })

    if (!apiDetails.requestBody) {
      setCurlStatement(
        assembleCurlStatement(
          apiDetails,
          resourceName,
          tokenFromInput,
          userFeatureFlags
        )
      )
    } else {
      validateRequestBodyJson(
        apiDetails.requestBody as any,
        apiDetails,
        tokenFromInput
      )
    }
  }

  const handleRequestBodyChange = (value: any): void => {
    const newApiDetails = {
      ...apiDetails,
      requestBody: {
        ...apiDetails.requestBody!,
        value: value,
      },
    }

    validateRequestBodyJson(value, newApiDetails, tokenFromInput)

    setApiDetails(newApiDetails)
  }

  const displayRequestBodyArea = (): React.ReactNode => {
    return (
      <>
        <p className="inputs-header">{t('generalDocumentation.requestBody')}</p>
        <div className="md-input-group request-body">
          <TryOutTextArea
            jsonBody={apiDetails.requestBody as { [key: string]: any }}
            handleChange={handleRequestBodyChange}
            example={apiDetails.requestExample}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <form className="try-out">
        <CodeSnippetHeader
          canCopy={canCopyCurl}
          copyText={curlStatement}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.request')}</span>
          }
        />
        <div
          className={`body ${
            (responseFromApi && responseFromApi.status && 'request-sent') || ''
          } ${apiDetails.requestBody ? 'has-request-body' : ''}`}
        >
          <p className="inputs-header">{t('tryOut.header')}</p>
          <BearerTokenInput
            handleAuthTokenInput={handleAuthTokenInput}
            toggleId="REST"
          />
          <hr className="try-out-divider" />
          {apiDetails.parameters?.length ? (
            <TryOutParameters
              changeCallback={handleParameterChange}
              keyDownCallback={(arg: KeyboardEvent<HTMLInputElement>): void =>
                onKeyDown(arg, runHttpRequest)
              }
              parameters={apiDetails.parameters}
            />
          ) : null}
          {apiDetails.requestBody ? displayRequestBodyArea() : null}
        </div>
        <div className="button-container">
          <TryOutRequestBtn
            isSendingRequest={sendingRequest}
            onClickHandler={runHttpRequest}
          />
        </div>
      </form>
      <RestTryOutResponse
        sendingRequest={sendingRequest}
        responseFromApi={responseFromApi}
      />
    </>
  )
}

export default JsonTryOut
