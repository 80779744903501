import React from 'react'
import { useTranslation } from 'react-i18next'
import TryOutTextArea from '../TryOutTextArea'
import TryOutInputLabel from '../TryOutInputLabel'
import FileUploadInput from '../FileUploadParameter'
import TryOutParameters from '../TryOutParameters'
import { ISchemaObject } from '../../../interfaces/schemaObjectInterfaces'
import TryOutSelect from '../TryOutSelect'

interface IFormDataRequestBody {
  handleChange: Function
  fileParams: any
  jsonParams: any
  simpleParams: any
  example: any
  onKeyDown: Function
}

const FormDataRequestBody: React.FC<IFormDataRequestBody> = ({
  handleChange,
  fileParams,
  jsonParams,
  simpleParams,
  example,
  onKeyDown,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <p className="inputs-header">{t('generalDocumentation.requestBody')}</p>
      {fileParams && fileParams.length
        ? fileParams.map((param: ISchemaObject, i: number) => {
            return (
              <FileUploadInput
                param={param}
                handleChange={handleChange}
                handleKeyDown={onKeyDown}
                key={i}
              />
            )
          })
        : null}

      {simpleParams && simpleParams?.length ? (
        <>
          <hr className="try-out-divider" />
          <TryOutParameters
            changeCallback={handleChange}
            keyDownCallback={onKeyDown}
            parameters={simpleParams}
            showTitle={false}
          />
        </>
      ) : null}
      <div className="md-input-group request-body">
        {jsonParams && jsonParams.length
          ? jsonParams.map((param: ISchemaObject, i: number) => {
              return (
                <div key={i}>
                  {param.contentType?.length ? (
                    <TryOutSelect param={param} handleChange={handleChange} />
                  ) : (
                    <TryOutInputLabel param={param} />
                  )}

                  <TryOutTextArea
                    jsonBody={param}
                    example={example[param.name as string]}
                    handleChange={handleChange}
                  />
                </div>
              )
            })
          : null}
      </div>
    </>
  )
}

export default FormDataRequestBody
