import React, { useState } from 'react'
import { Select, SelectOption } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import TryOutInputLabel from '../TryOutInputLabel'
import { ISchemaObject } from '../../../interfaces/schemaObjectInterfaces'

interface ITryOutSelect {
  param: ISchemaObject
  handleChange: Function
}

const TryOutSelect: React.FC<ITryOutSelect> = ({ param, handleChange }) => {
  const { t } = useTranslation()
  const [selectState, setSelectState] = useState(false)
  return (
    <div className="input-outer-box">
      <TryOutInputLabel param={param} />
      <Select
        onSelect={(val: any): void => {
          handleChange(val[0].value, param, 'selectedContentType')
          setSelectState(true)
        }}
        defaultValue={t('tryOut.selectContentType')}
        className={selectState ? 'selected' : 'no-selection'}
      >
        {(param.contentType as string[]).map((type: string, i: number) => {
          return <SelectOption value={type} label={type} key={i} />
        })}
      </Select>
    </div>
  )
}

export default TryOutSelect
