import React from 'react'
import { Button } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'

interface ITryOutRequestBtn {
  isSendingRequest: boolean
  onClickHandler: Function
}

const TryOutRequestBtn: React.FC<ITryOutRequestBtn> = ({
  isSendingRequest,
  onClickHandler,
}) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={(): Promise<void> => onClickHandler()}
      ariaLabel="Run HTTP Request"
      color="green"
      size="28"
      loading={isSendingRequest}
      className="request-button"
    >
      {t('tryOut.run')}
    </Button>
  )
}

export default TryOutRequestBtn
