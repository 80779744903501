import React from 'react'

interface ResponeCodeProps {
  code: number
}

const ResponseCode: React.FC<ResponeCodeProps> = ({ code }) => {
  const codeColor = code.toString()[0] === '2' ? 'green' : 'red'
  return <span className={`response-code ${codeColor}`}>{code}</span>
}

export default ResponseCode
