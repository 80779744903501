import React, { ChangeEvent } from 'react'

interface ITryOutTextArea {
  jsonBody: { [key: string]: any }
  handleChange: Function
  example: { [key: string]: any }
}

const TryOutTextArea: React.FC<ITryOutTextArea> = ({
  jsonBody,
  handleChange,
  example,
}) => {
  return (
    <textarea
      className="md-input"
      name="textArea"
      value={jsonBody.value}
      defaultValue={JSON.stringify(example, null, 2) || '{}'}
      onChange={(input: ChangeEvent<HTMLTextAreaElement>): void => {
        handleChange(input.target.value, jsonBody)
      }}
    ></textarea>
  )
}

export default TryOutTextArea
