import React, {
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  useRef,
  useState,
} from 'react'
import { Icon, Input } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import TryOutInputLabel from '../TryOutInputLabel'
import IconButton from '../../IconButton'
import { ISchemaObject } from '../../../interfaces/schemaObjectInterfaces'

interface IFileUploadInput {
  handleChange: Function
  handleKeyDown?: Function
  param: ISchemaObject
}

const FileUploadInput: React.FC<IFileUploadInput> = ({
  handleChange,
  handleKeyDown,
  param,
}) => {
  const { t } = useTranslation()
  const fileInput: MutableRefObject<null | HTMLInputElement> = useRef(null)
  const [fileName, setFileName] = useState('')

  return (
    <div className="input-outer-box">
      <TryOutInputLabel param={param} />
      <input
        className="hidden-file-input"
        type="file"
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          if (event.target.files && event.target.files.length) {
            handleChange(event.target.files[0], param)
            setFileName(event.target.files[0].name)
          }
        }}
        onKeyDown={(arg: KeyboardEvent<HTMLInputElement>): void => {
          if (handleKeyDown) {
            handleKeyDown(arg)
          }
        }}
        value={''}
        ref={fileInput}
      />
      <div className="file-input">
        <Input
          name="file-upload-label"
          htmlId="file-upload-label"
          placeholder={t('tryOut.fileUploadPlaceholder')}
          disabled
          value={fileName}
        />
        <IconButton
          tooltipText={t('tryOut.fileUploadIconTooltip')}
          icon={<Icon name="icon-upload_18" />}
          onClick={(): void => fileInput?.current?.click()}
        />
      </div>
    </div>
  )
}

export default FileUploadInput
