import React from 'react'
import { IRestTryOutProps } from '../../../interfaces/tryOutInterfaces'
import JsonTryOut from '../JsonTryOut'
import FormDataTryOut from '../FormDataTryOut'
import { MULTIPART_FORM_DATA } from '../../../constants/tryOutConstants'

const RestTryOut: React.FC<IRestTryOutProps> = ({
  api,
  name,
  resourceName,
}) => {
  return (
    <>
      {api.requestBody?.contentType === MULTIPART_FORM_DATA ? (
        <FormDataTryOut api={api} name={name} resourceName={resourceName} />
      ) : (
        <JsonTryOut api={api} name={name} resourceName={resourceName} />
      )}
    </>
  )
}

export default RestTryOut
