export const jsonSyntaxErrorResponse = {
  status: 400,
  data: {
    error: {
      key: '400',
      message: [
        {
          description: 'JSON input is invalid',
        },
      ],
    },
  },
}

export const MULTIPART_FORM_DATA = 'multipart/form-data'
export const APPLICATION_JSON = 'application/json'
